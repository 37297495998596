import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import Logo from "../../components/Logo";

import Navigation from "../../components/NavigationWithLogo";
import "../../style/all.css";

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const termine = get(this, "props.data.contentfulTermine");

    return (
      <Layout>
        <Logo />
        <Navigation menuText="Termine" />

        <div>
          <Helmet title={`Termine | ${siteTitle}`}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
          </Helmet>
          <section className="section-neues">
            <div className="content termine">
              <h1>Termine</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: termine.textblock.childMarkdownRemark.html,
                }}
              />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query TermineQuery {
    contentfulTermine(id: { eq: "249466d6-0c19-5854-90dc-fabb0f58112f" }) {
      textblock {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
